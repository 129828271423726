import logo from './logo.svg';
import './App.css';
import {useState} from "react";

function App() {
  const [counter, setCounter] = useState(0);

  const clickHandler = () => {
    setCounter((prevCounter) => (prevCounter + 1));
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"  />
        <p onClick={clickHandler}>
          Clicked {counter} times.
        </p>
      </header>
    </div>
  );
}

export default App;
